<template>
  <base-section
    id="k-d-a-partner"
    class="secondary"
    space="72"
  >
    <v-container>
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col>
          <component
            :is="m_objTitle.strComponent"
            :class="getTextClasses(m_objTitle.eType , ['text-center'])"
          >
            {{ m_objTitle.strText }}
          </component>

          <base-divider
            class="my-3"
            align="center"
            color="primary1"
          />
        </v-col>
      </v-row>

      <v-row
        align="center"
        justify="space-between"
      >
        <v-col
          v-for="(item, i) in m_arrPartners"
          :key="i"
          cols="6"
          sm="4"
          md="2"
        >
          <base-img
            :src="require('@/assets/logos/'+ item.strPath)"
            contain
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import TextClass from '@/mixins/text-class'
  import Projects from '@/mixins/projects'

  export default {
    name: 'SectionKDAPartner',

    mixins: [Projects, TextClass],

    computed: {
      m_objTitle () {
        return {
          strText: 'WHO\'S USING KEDA TECH ?',
          strComponent: 'h1',
          eType: this.eContentType.TITLE_1,
        }
      },

      m_arrPartners () {
        return this.g_arrPartners
      },
    },
  }
</script>
